export function useCartItem(cartItem) {
  const app = useNuxtApp();
  const store = useDefaultStore();
  const shopwareStore = useShopwareStore();

  const { requestHeaders, requestPath } = utilRequests();

  const { removeItem: removeCartItem, changeCartItemQuantity } = useCart();

  const itemQuantity = computed(() => cartItem.quantity);
  const itemImageThumbnailUrl = computed(() =>
    getProductMainImageUrl(cartItem)
  );
  const itemRegularPrice = computed(
    () => cartItem.price?.listPrice?.price || cartItem.price?.unitPrice
  );
  const itemSpecialPrice = computed(
    () => cartItem.price?.listPrice && cartItem.price.unitPrice
  );
  const itemOptions = computed(
    () => (cartItem.type === "product" && cartItem.payload?.options) || []
  );
  const itemStock = computed(() => cartItem.deliveryInformation?.stock);
  const itemType = computed(() => cartItem.type);
  const isProduct = computed(() => cartItem.type === "product");
  const isPromotion = computed(() => cartItem.type === "promotion");

  const removeItem = () => {
    removeCartItem(cartItem);
  };

  const changeItemQuantity = async (quantity) => {
    const product = cartItem;

    await changeCartItemQuantity(product, quantity);
  };

  return {
    changeItemQuantity,
    removeItem,
    lineItem: computed(() => cartItem),
    itemRegularPrice,
    itemSpecialPrice,
    itemOptions,
    itemStock,
    itemQuantity,
    itemType,
    itemImageThumbnailUrl,
    isProduct,
    isPromotion,
  };
}
